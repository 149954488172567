import { environmentConfig } from "@src/appV2/environment";
import { logAndThrowApiError, logApiFailureEvent } from "@src/lib/analytics";
import { Agent, Facility, Shift } from "@src/lib/interface";
import request from "superagent";

import { getAuthHeader } from "../../superagent";
import { RequestOpenShiftOptions, requestFacilityOpenShiftCountOptions } from "../model";

export const sendCalendarOpenActivity = async (): Promise<void> => {
  try {
    await request
      .post(`${environmentConfig.REACT_APP_BASE_API_URL}/calendar/open`)
      .set(await getAuthHeader())
      .send({ isNative: true });
  } catch (error) {
    logApiFailureEvent(error);
  }
};

export const fetchOpenShifts = async (
  queryParams: RequestOpenShiftOptions
): Promise<Shift[] | undefined> => {
  const baseUrl = environmentConfig.REACT_APP_BFF_URL;

  return await request
    .get(`${baseUrl}/calendar/openShifts`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .query(queryParams)
    .then(({ body }) => body?.openAgentShifts)
    .catch(logApiFailureEvent);
};

export const updateDistancePreference = async (distance: number): Promise<Agent> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/preference`)
    .set(await getAuthHeader())
    .send({ key: "distance", value: distance })
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const removeRecommendedDistance = async (): Promise<Agent> => {
  return await request
    .delete(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/recommendedDistance`)
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export const FETCH_FACILITY_OPEN_SHIFT_COUNT_ENDPOINT = "/calendar/facilityOpenShiftCount";
export const fetchFacilityOpenShiftCount = async (
  options: requestFacilityOpenShiftCountOptions
): Promise<Facility[]> => {
  const baseUrl = environmentConfig.REACT_APP_BFF_URL;

  return await request
    .get(`${baseUrl}${FETCH_FACILITY_OPEN_SHIFT_COUNT_ENDPOINT}`)
    .set(await getAuthHeader())
    .query({ ...options, isAgent: true })
    .then(({ body }) => body)
    .catch(logAndThrowApiError);
};

export const updatePreference = async (
  preference: string,
  value: number | string,
  shouldThrowError = false
): Promise<Agent> => {
  return await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/agentProfile/preference`)
    .set(await getAuthHeader())
    .send({ key: preference, value: value })
    .then(({ body }) => body)
    .catch((err) => logApiFailureEvent(err, shouldThrowError));
};
